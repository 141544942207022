import { BrowserView, MobileView } from 'react-device-detect';
import styles from './index.module.scss';

interface Props {

}

export default function Footer(props: Props) {
  const { } = props;
  return (
    <div className={styles.wrapper}>
      <BrowserView>
      <div className={styles.bigTitle}>
      <h5>Coded by hand by Joep, Eva & Lei.<br></br>No presh, only flash ⚡️ © 2023</h5>
      </div>
      </BrowserView>
      <MobileView>
      <div className={styles.bigTitle}>
      <h6>Coded by hand by Joep, Eva & Lei.<br></br>No presh, only flash ⚡️ © 2023</h6>
      </div>
      </MobileView>
    </div>
  );
}
