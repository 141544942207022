import { BrowserView, MobileView } from 'react-device-detect';
import styles from './index.css';
import fallbackImage from './../../assets/images/mitdbo.jpg'
import maasImage from './../../assets/images/maas.jpeg'
import leiImage from './../../assets/images/lei.jpeg'
import evelineImage from './../../assets/images/eveline.jpeg'
import jobImage from './../../assets/images/job.jpeg'
import { createGradeString } from '../../pages/Landing/grades.js'
import React, { useEffect, useState } from 'react'


function DayCounterLabel(props) {
  const [dayCounterData, setDayCounterData] = useState([{}])

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "/api/daycounter").then(
      Response => Response.json()
    ).then(
      data => {
        console.log(data)
        const newdata = data.users.find(function (element) { return element.uid === props.uid })
        setDayCounterData(newdata)


      }
    )
  }, [])

  console.log(dayCounterData)
  return <div className='counterLabel'>{dayCounterData.days} days</div>
  // return <div>{dayCounterData.users.map((user) => (
  //   <p>{user.uid}</p>
  // ))}</div>
}

function GradeLabel(props) {
  return <div className='gradeLabel'>
    {props.rank === 0 ? ("🥇 ") : ("")}
    {props.rank === 1 ? ("🥈 ") : ("")}
    {props.rank === 2 ? ("🥉 ") : ("")}
    {createGradeString(props.grade)}</div>
}
function UserImage(props) {
  // eslint-disable-next-line no-lone-blocks
  
  //Maas Goudswaard picture
  if (props.uid === 9914749645) {
    return <img className="userImage" src={maasImage} alt="No UserImage"/>
  }
  //Lei Nelissen picture
  if (props.uid === 9806866653) {
    return <img className="userImage" src={leiImage} alt="No UserImage"/>
  }
  //Eveline Heesterbeek picture
  if (props.uid === 1100130380) {
    return <img className="userImage" src={evelineImage} alt="No UserImage"/>
  }
  //Job Engel picture
  if (props.uid === 3674105960) {
    return <img className="userImage" src={jobImage} alt="No UserImage"/>
  }

  else {
    return <img className="userImage" src={props.image} alt="No UserImage"/>
  }
}

export default function UserCard(props) {
  const { } = props;
  // console.log("the following data I have collected:", props);
  return (
    <>
    <BrowserView className='userCard'>
        <h3>{props.name}</h3>
        <UserImage image={props.image} uid={props.uid}/>
        <div className='userStats'>
          <GradeLabel grade={props.grade} rank={props.rank} />
          <DayCounterLabel uid={props.uid} />
        </div>
    </BrowserView>
    <MobileView className='userCardMobile'>
      <UserImage image={props.image} uid={props.uid}/>
        <h3>{props.name}</h3>
        <div className='userStatsMobile'>
          <GradeLabel grade={props.grade} rank={props.rank} />
          <DayCounterLabel uid={props.uid} />
        </div>
      
    </MobileView>
  </>)
}


