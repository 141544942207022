import './index.css';
import Header from '../../components/Header/index'
import Footer from '../../components/Footer/index'
import Sidebar from '../../components/Sidebar/index.js'


interface Props {

}

export default function Template(props: Props) {
  const { } = props;
  return (
    <div className='wrapper'>
    <Header />
      <div className="App" id="outer-container">
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
       
      </div>
      <div className='basicContainer'><h2> 👷‍♀️🚧👷‍♂️ Under Construction 👷‍♀️🚧👷‍♂️</h2></div>
      
    <Footer/>
  </div>
  );
}
