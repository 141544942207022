const frenchBoulderGrades = {
    key: "french_boulder",
    name: "Font.",
    description: "French numerical bouldering system",
    types: ["boulder"],
    data: [
        { value: 2, name: "2", level: 0 },
        { value: 2.5, name: "2", level: 0, name0: "2" },
        { value: 2.75, name: "2⁺", level: 1 },
        { value: 3, name: "3ᴀ", level: 0, name0: "3" },
        { value: 3.33, name: "3ʙ", level: 1 },
        { value: 3.67, name: "3ᴄ", level: 1 },
        { value: 4, name: "4ᴀ", level: 0, name0: "4" },
        { value: 4.33, name: "4ʙ", level: 1 },
        { value: 4.67, name: "4ᴄ", level: 1 },
        { value: 5, name: "5ᴀ", level: 0, name0: "5", name1: "5ᴀ(⁺)" },
        { value: 5.17, name: "5ᴀ⁺", level: 2 },
        { value: 5.33, name: "5ʙ", level: 1, name1: "5ʙ(⁺)" },
        { value: 5.5, name: "5ʙ⁺", level: 2 },
        { value: 5.67, name: "5ᴄ", level: 1, name1: "5ᴄ(⁺)" },
        { value: 5.83, name: "5ᴄ⁺", level: 2 },
        { value: 6, name: "6ᴀ", level: 0, name0: "6", name1: "6ᴀ(⁺)" },
        { value: 6.17, name: "6ᴀ⁺", level: 2 },
        { value: 6.33, name: "6ʙ", level: 1, name1: "6ʙ(⁺)" },
        { value: 6.5, name: "6ʙ⁺", level: 2 },
        { value: 6.67, name: "6ᴄ", level: 1, name1: "6ᴄ(⁺)" },
        { value: 6.83, name: "6ᴄ⁺", level: 2 },
        { value: 7, name: "7ᴀ", level: 0, name0: "7", name1: "7ᴀ(⁺)" },
        { value: 7.17, name: "7ᴀ⁺", level: 2 },
        { value: 7.33, name: "7ʙ", level: 1, name1: "7ʙ(⁺)" },
        { value: 7.5, name: "7ʙ⁺", level: 2 },
        { value: 7.67, name: "7ᴄ", level: 1, name1: "7ᴄ(⁺)" },
        { value: 7.83, name: "7ᴄ⁺", level: 2 },
        { value: 8, name: "8ᴀ", level: 0, name0: "8", name1: "8ᴀ(⁺)" },
        { value: 8.17, name: "8ᴀ⁺", level: 2 },
        { value: 8.33, name: "8ʙ", level: 1, name1: "8ʙ(⁺)" },
        { value: 8.5, name: "8ʙ⁺", level: 2 },
        { value: 8.67, name: "8ᴄ", level: 1, name1: "8ᴄ(⁺)" },
        { value: 8.83, name: "8ᴄ⁺", level: 2 },
        { value: 9, name: "9ᴀ", level: 0, name0: "9", name1: "9ᴀ(⁺)" },
        { value: 9.17, name: "9ᴀ⁺", level: 2 },
        { value: 9.33, name: "9ʙ", level: 1, name1: "9ʙ(⁺)" },
        { value: 9.5, name: "9ʙ⁺", level: 2 },
    ],
}

const monkBoulderGrades = {
    key:"custom_boulder",
    name:"Custom",
    description:"Custom grade system for boulders at Monk Eindhoven",
    types:["boulder"],
    data:[
        {value:2,name:2,level:0},
        {value:3,name:3,level:0,name0:3},
        {value:3.33,name:"3+",level:1},
        {value:4,name:"4",level:1},
        {value:4.33,name:"4+",level:1},
        {value:4.67,name:"5-",level:2},
        {value:5,name:"5",level:1},
        {value:5.5,name:"5+",level:1},
        {value:6,name:"6ᴀ",level:0,},
        {value:6.17,name:"6ᴀ⁺",level:2},
        {value:6.33,name:"6ʙ",level:1},
        {value:6.5,name:"6ʙ⁺",level:2},
        {value:6.67,name:"6ᴄ",level:1},
        {value:6.83,name:"6ᴄ⁺",level:2},
        {value:7,name:"7ᴀ",level:0},
        {value:7.17,name:"7ᴀ⁺",level:2},
        {value:7.33,name:"7ʙ",level:1},
        {value:7.5,name:"7ʙ⁺",level:2},
        {value:7.67,name:"7ᴄ",level:1},
        {value:7.83,name:"7ᴄ⁺",level:2},
        {value:8,name:"8ᴀ",level:0},
        {value:8.17,name:"8ᴀ⁺",level:2},
        {value:8.33,name:"8ʙ",level:1},
        {value:8.5,name:"8ʙ⁺",level:2},
    ]}

export function createGradeString(inputGrade) {
    var i = 0;
    while (monkBoulderGrades.data[++i].value < inputGrade);
    if (i > 2) {
        const baseGradeValue = monkBoulderGrades.data[i - 1].value
        const nextGradeValue = monkBoulderGrades.data[i].value
        const progressPercentage = ((inputGrade - baseGradeValue) / (nextGradeValue - baseGradeValue)) * 100;
        return "" + monkBoulderGrades.data[i - 1].name + " (+" + progressPercentage.toFixed(1) + "%)";
    }
    else {
        return monkBoulderGrades.data[1].name;
    }

}

