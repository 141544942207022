import styles from './index.module.scss';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Line, Bar } from '@visx/shape';
//import gradeHistory, { GradeSample } from './gradeHistory.d';
import appleStock, { AppleStock } from '@visx/mock-data/lib/mocks/appleStock';
import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear, scaleLog } from '@visx/scale';
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector, min } from 'd3-array';
import { timeFormat } from 'd3-time-format'
import { LinePath } from '@visx/shape';
import { createGradeString } from '../../pages/Landing/grades.js'

interface GradeSample {
  date: string;
  grade: number;
}

interface User {
  history:{
    data: GradeSample[];
  }
  user: {
    full_name: string;
  };
}

//fake data 
type TooltipData = {
  sample: GradeSample;
  user: string;
};

//color exports
export const tooltipcolor = 'rgba(61, 64, 237, 1)';
export const background = 'rgba(61, 64, 237, 0.8)';
export const background2 = 'rgba(250, 250, 250, 0.6)';
export const accentColor = 'rgba(255, 255, 255, 1)';
export const accentColorDark = 'rgba(255, 255, 255, 1)';

// All user colors:
export const JoepColor = 'rgba(255, 70, 70, 1)'; // red
export const SophieColor = 'rgba(108, 205, 251, 1)' // light blue
export const EvaColor = 'rgba(251, 229, 108, 1)' // yellow
export const MarleenColor = 'rgba(172, 250, 171, 1)' // light green
export const MaasColor = 'rgba(5, 0, 150, 1)' // dark blue
export const AntonColor = 'rgba(244, 126, 245, 1)' // pink 
export const JobColor = 'rgba(88, 7, 135, 1)' // purple 
export const EvelineColor = 'rgba(34, 100, 29, 1)' // dark green 
export const LeiColor = 'rgba(254, 178, 20, 1)' // orange 
export const LizaColor = 'rgba(166, 255, 221, 1)' // mint 

export const userColors = [
  JoepColor,
  SophieColor,
  EvaColor,
  MarleenColor,
  MaasColor, 
  AntonColor,
  JobColor,
  EvelineColor,
  LeiColor,
  LizaColor,
]

const tooltipStyles = {
  ...defaultStyles,
  background,
  border: '2px solid white',
  color: 'white',
};

// util
const formatDate = timeFormat("%Y-%m-%d");

// accessors
const getDate = (d: GradeSample) => new Date(d.date);
const getGradeValue = (d: GradeSample) => d?.grade !== undefined ? Number(d.grade) : 0;
const bisectDate = bisector<GradeSample, Date>((d) => new Date(d.date)).left;

export type CurveProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  showControls?: boolean;
};

export default withTooltip<CurveProps, TooltipData>(
  ({
    width,
    height,
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }: CurveProps & WithTooltipProvidedProps<TooltipData>) => {
    if (width < 10) return null;

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    
    const [backendData, setBackendData] = useState<User[]>([]);
    
    useEffect(() => {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/users").then(
        Response => Response.json()
      ).then(
        data => {
          setBackendData(data.users)
        }
      )
    }, [])

    // scales
    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, innerWidth + margin.left],
          // we map over all the users and combine the array. we return the history and replace the users
          domain: extent(backendData.map((user, i) => user.history.data.slice(37)).flat(), getDate) as [Date, Date],
        }),
      [innerWidth, margin.left, backendData],
    );
    const gradeValueScale = useMemo(
      () =>
      scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          // domain: extent(backendData.map((user, i) => user.history.data.slice(37)).flat(), getGradeValue) as any,
          domain: [min(backendData.map((user, i) => user.history.data.slice(37)).flat(), getGradeValue), max(backendData.map((user, i) => user.history.data.slice(37)).flat(), getGradeValue)]as any, 
          nice: true,
        }),
      [innerHeight, margin.top, backendData],
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => 
      {
        const { x, y } = localPoint(event) || { x: 0, y: 0 };
        const x0 = dateScale.invert(x);
        const y0 = gradeValueScale.invert(y);
        let index = bisectDate(backendData[0].history.data, x0, 1);
        
        const d0 = backendData[0].history.data[index - 1];
        const d1 = backendData[0].history.data[index];
        if (d1 && getDate(d1)) {
          index = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? index: (index -1);
        }
        const d = backendData.reduce((closest, user) => {
          const currentGradeValue = user.history.data[index]
          const currentDelta = Math.abs(y0 - closest.sample.grade);
          const currentUserDelta = Math.abs(y0 - currentGradeValue.grade);
          if (currentUserDelta > currentDelta ) {
            return closest;
          }
          else {
            return {
              sample: currentGradeValue,
              user: user.user.full_name,
            }
          }
        }, {
          sample: backendData[0].history.data[index],
          user: backendData[0].user.full_name,
        });
      
        showTooltip({
          tooltipData: d,
          tooltipLeft: dateScale(getDate(d.sample)),
          tooltipTop: gradeValueScale(getGradeValue(d.sample)),
        });
      },
      [showTooltip, gradeValueScale, dateScale, backendData],
    );

    console.log(backendData);

    return (
      <div className={styles.wrapper}>
        <svg width={width} height={height}>
          <Group >
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill="url(#area-background-gradient)"
            />
            <LinearGradient id="area-background-gradient" from={background} to={background2} />
            <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
            <GridRows
              left={margin.left}
              scale={gradeValueScale}
              width={innerWidth}
              strokeDasharray="1,3"
              stroke={accentColor}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
            <GridColumns
              top={margin.top}
              scale={dateScale}
              height={innerHeight}
              strokeDasharray="1,3"
              stroke={accentColor}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
            {backendData.map((user, i) => 
              <LinePath<GradeSample>
                curve={curveMonotoneX}
                data={user.history.data.slice(37)}
                x={(d) => dateScale(getDate(d)) ?? 0}
                y={(d) => gradeValueScale(getGradeValue(d)) ?? 3}
                stroke={userColors[i]}
                strokeWidth={2}
                shapeRendering="geometricPrecision"
              />)}
            <Bar
              x={margin.left}
              y={margin.top}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
              rx={14}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: margin.top }}
                  to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                  stroke={accentColorDark}
                  strokeWidth={2}
                  pointerEvents="none"
                  strokeDasharray="5,2"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop + 1}
                  r={4}
                  fill="black"
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  r={5}
                  fill={tooltipcolor}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            )}
          </Group >
          <AxisBottom
            // force remount when this changes to see the animation difference
            scale={dateScale}
            orientation={"bottom"}
            top={height}
            stroke={tooltipcolor}
          // numTicks={6}
          />
          <AxisLeft
            // force remount when this changes to see the animation difference
            scale={gradeValueScale}
            orientation={"left"}
            top={0}
            stroke={tooltipcolor}
          // numTicks={6}
          />
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop - 12}
              left={tooltipLeft + 12}
              style={tooltipStyles}
            >
              {createGradeString(getGradeValue(tooltipData.sample))} {`${tooltipData.user.split(" ")[0]}`}
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + margin.top - 40}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
              }}
            >
              {formatDate(getDate(tooltipData.sample))}
            </Tooltip>
          </div>
        )}

      </div>
    );
  },
);