import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import './index.css';
import Header from '../../components/Header/index.tsx'
import Logo from '../../components/Logo/index.tsx'
import UserCard from '../../components/UserCard/index.js'
import Footer from '../../components/Footer/index.tsx'
import Sidebar from '../../components/Sidebar/index.js'

function App() {
  const [backendData, setBackendData] = useState([{}])

  useEffect(() => {
    //const userInterval = setInterval((function execute() {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/users").then(
        Response => Response.json()
      ).then(
        data => {
          setBackendData(data)
        }
      )
  }, [])

  return (
    <div className='body'>
      {/* <Logo /> */}
      <Header />
      <div className="App" id="outer-container">
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <div className='usersContainer'>
        {(typeof backendData.users === 'undefined') ? (
          <p>Loading data</p>
        ) : (
          <div className='cardContainer'>
            {backendData.users.map((user, i) => (
              <UserCard
                key={i}
                uid={user.user.uid}
                rank={i}
                name={user.user.full_name}
                grade={user.grade}
                image={user.user.avatar} />
            ))}
          </div>
        )}
      </div>
      </div>

      <Footer/>
    </div>
  )
}
export default App