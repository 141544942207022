import './index.css';
import Header from '../../components/Header/index'
import Logo from '../../components/Logo/index'
import Footer from '../../components/Footer/index'
import Sidebar from '../../components/Sidebar/index.js'
import UserGraph from '../../components/UserGraph/index';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { useEffect, useState } from 'react';

interface Props {

}

export default function GraphOverview(props: Props) {
  const { } = props;
  return (
    <div className='wrapper'>
    <Header />
      <div className="App" id="outer-container">
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <div className='graphContainer'>
          <ParentSize>{({ width, height }) => <UserGraph width={width} height={height} />}</ParentSize>
        </div>
      </div>
    <Footer/>
  </div>
  );
}
