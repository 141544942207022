import styles from './index.css';
import React from 'react';
import { slide as Menu } from 'react-burger-menu';

export default props => {
  return (
    <Menu right>
      <a className="menu-item" href="/">
        🏅 &nbsp; Leaderboard
      </a>
      <a className="menu-item" href="/graph">
        📉 &nbsp; Graphs
      </a>
      <a className="menu-item" href="/events">
        🎉 &nbsp; International Events
      </a>
      <a className="menu-item" href="/pictures">
        📸 &nbsp; Pictures
      </a>
      <a className="menu-item" href="/settings">
        ⚙️ &nbsp; Settings
      </a>
    </Menu>
  );
};