import {
  Routes,
  Route,
} from 'react-router-dom';
import styles from './index.module.scss';
import Landing from '../Landing';
import GraphOverview from '../GraphOverview';
import {
  HOMELINK, GRAPHLINK, EVENTSLINK, PICTURESLINK, SETTINGSLINK
} from './links';
import React from 'react';
import Template from '../Template';

function App() {
  return (
    <div className={styles.wrapper}>
      <Routes>
        <Route path="/">
          <Route path={HOMELINK} element={<Landing />} />
          <Route path={GRAPHLINK} element={<GraphOverview />} />
          <Route path={EVENTSLINK} element={<Template />} />
          <Route path={PICTURESLINK} element={<Template />} />
          <Route path={SETTINGSLINK} element={<Template />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
