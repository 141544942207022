import { BrowserView, MobileView } from 'react-device-detect';
import Sidebar from '../Sidebar';
import styles from './index.module.scss';

interface Props {

}

export default function Header(props: Props) {
  const { } = props;
  return (
    <div className={styles.wrapper}>
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <BrowserView>
      <div className={styles.bigTitle}>
        <h2>🧗‍♂️ KlimKlub International</h2>
      </div>
      </BrowserView>
      <MobileView>
        <div className={styles.bigTitle}>
        <h3>🧗‍♂️ KlimKlub <br></br>International</h3>
        </div>
      </MobileView>
    </div>
  );
}
